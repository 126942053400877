import React from 'react'
import aboutImage from '../../assets/images/education/Education1.png'
import starIcon from '../../assets/images/star-icon.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    About Us
                                </span>
                                <h2>Data management made easier</h2>
                                <p>Smarter Spreadsheets is here to meet your needs.  Whether through custom spreadsheets to help you organize and analyze your data or professional development to help your staff become more confident in their spreadsheet ability, we can help!</p>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>Smarter Spreadsheets LLC seeks to improve the lives of educators and school personnel by leveraging technology.  The tools offered in spreadsheets can save hours of time and create products that do more than analog comparables.  Through individualized collaboration, Smarter Spreadsheets can create projects and teach skills that give teachers more time to focus on helping students achieve their goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;